import axios from 'axios';

// Create an Axios instance
const API = axios.create({
  baseURL: 'https://api.kalender.gronde.eu/api',
  withCredentials: true, // Ensures cookies are sent with requests
});

let refreshTokenInProgress = false;
let refreshTokenPromise = null;

// Function to refresh the access token
const refreshAccessToken = async () => {
  // If a refresh is already in progress, return the existing promise
  if (refreshTokenInProgress) {
    return refreshTokenPromise;
  }

  refreshTokenInProgress = true;

  refreshTokenPromise = API.post('/refresh-token')
    .then((response) => {
      console.log('Response From Refresh Token:', response.data);

      if (!response.data || !response.data.accessToken) {
        throw new Error('Access token not found in response');
      }

      const newAccessToken = response.data.accessToken;
      localStorage.setItem('access_token', newAccessToken);

      return newAccessToken;
    })
    .catch((error) => {
      console.error('Error refreshing access token:', error);
      localStorage.removeItem('access_token');
      throw error;
    })
    .finally(() => {
      refreshTokenInProgress = false;
      refreshTokenPromise = null;
    });

  return refreshTokenPromise;
};

// Request Interceptor
API.interceptors.request.use(
  (config) => {
    // Skip setting the Authorization header for the refresh token request
    if (config.url !== '/refresh-token') {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.error('Error in Request Interceptor:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error is due to 401 Unauthorized and no retry has been made
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite retry loops

      try {
        // Attempt to refresh the access token
        const newAccessToken = await refreshAccessToken();
        console.log('New access token:', newAccessToken);

        // Update original request headers with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request
        return API(originalRequest);
      } catch (err) {
        console.error('Error during token refresh:', err);

        // Clear stored token if refresh fails
        localStorage.removeItem('access_token');
        return Promise.reject(err); // Let the calling component handle navigation
      }
    }

    return Promise.reject(error);
  }
);

export default API;
