import React from 'react';
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './context/authContext';
import './index.css';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
          <Route
            path="/user/:user"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<div>Oops! Something went wrong. Please try again later.</div>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;



