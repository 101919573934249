import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './context/authContext';

const PrivateRoute = ({ children }) => {
  const { user, role, loading } = useContext(AuthContext);
console.log(user,role,loading);

  if (loading) {
    return <div>Loading...</div>;  // Loading state anzeigen, bis Authentifizierung geladen ist
  }

  if (!user || role !== 'admin') {
    return <Navigate to="/login" />;  // Benutzer ist nicht authentifiziert, leite zu login weiter
  }

  return children;  // Zeige geschützten Inhalt an, wenn Benutzer authentifiziert ist
};

export default PrivateRoute;
