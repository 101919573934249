import React from 'react';
import styled from 'styled-components';

const DeleteConfirmationContainer = styled.div`
  display: ${props => props.showDeleteConfirmation ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ConfirmationBox = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
`;

const ConfirmationText = styled.p`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #ccc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
`;

function DeleteConformationComponent({ showDeleteConfirmation, onDeleteConfirmation, onCancelConfirmation }) {
  return (
    <DeleteConfirmationContainer showDeleteConfirmation={showDeleteConfirmation}>
    <ConfirmationBox>
      <ConfirmationText>Möchten Sie diese Filiale wirklich löschen?</ConfirmationText>
      <ButtonContainer>
        <ConfirmButton onClick={onDeleteConfirmation}>Ja</ConfirmButton>
        <CancelButton onClick={onCancelConfirmation}>Abbrechen</CancelButton>
      </ButtonContainer>
    </ConfirmationBox>
  </DeleteConfirmationContainer>
);
  
}

export default DeleteConformationComponent
