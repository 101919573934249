import styled from 'styled-components';

export const MitarbeiterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

`;

export const AddNewButton = styled.button`
  font-size: 16px;
  padding: 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #c82333;
  }
`;

export const DetailsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;

  

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #007bff;
    color: white;
  }
`;

export const TableCell = styled.td`
  text-align: center;
`;

export const ActionButton = styled.button`
  font-size: 16px;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #218838;
  }
`;

export const InputField = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;
