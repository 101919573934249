import React, { useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  display: ${props => props.showEdit ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FormWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.save {
    background-color: #28a745;
    color: white;

    &:hover {
      background-color: #218838;
    }
  }

  &.cancel {
    background-color: #dc3545;
    color: white;

    &:hover {
      background-color: #c82333;
    }
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
`;

function EditFormComponent({ formData, handleChange, handleSubmit, setShowEdit }) {
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleCheckboxChange = () => {
    setShowPasswordFields(!showPasswordFields);
  };

  const handleCancel = () => {
    setShowEdit(false);
  };

  return (
    <FormContainer showEdit={true} onClick={() => setShowEdit(false)}>
      <FormWrapper onClick={(e) => e.stopPropagation()}>
        <h2>Bearbeite Filiale {formData.name}</h2>
        <Form onSubmit={handleSubmit}>
          <label>
            Name:
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </label>
          <label>
            Username:
            <Input type="text" name="username" value={formData.username} onChange={handleChange} />
          </label>
          <label>
            Referenznummer:
            <Input type="text" name="rollenum" value={formData.rollenum} onChange={handleChange} />
          </label>
          <CheckboxLabel>
            <Input type="checkbox" checked={showPasswordFields} onChange={handleCheckboxChange} />
            Passwort ändern
          </CheckboxLabel>
          {showPasswordFields && (
            <>
              <label>
                Neues Passwort:
                <Input type="password" name="passwort" value={formData.passwort} onChange={handleChange} />
              </label>
              <label>
                Passwort bestätigen:
                <Input type="password" name="passwortBestaetigen" value={formData.passwortBestaetigen} onChange={handleChange} />
              </label>
            </>
          )}
          <ButtonGroup>
            <Button type="submit" className="save">Speichern</Button>
            <Button type="button" className="cancel" onClick={handleCancel}>Abbrechen</Button>
          </ButtonGroup>
        </Form>
      </FormWrapper>
    </FormContainer>
  );
}

export default EditFormComponent;
