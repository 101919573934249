import React, { useState } from 'react';
import Select from 'react-select';
import API from '../../context/axiosSetup';

import { FormContainer, FormWrapper, Form, Input, Button } from './Addmitarbeiter.element'; // Stellen Sie sicher, dass diese Pfade korrekt sind

function AddNewMitarbeiter({ onCancel, filialeOptions }) {


  const [formData, setFormData] = useState({
    name: '',
    farbe: '',
    filiale: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      filiale: selectedOptions ? selectedOptions.map(option => option.id) : []
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await API.post('/addnewMitarbeiter', formData);
      
      console.log('Server Response:', response.data);
  
      onCancel(); // Schließen des Formulars nach erfolgreichem Senden der Daten
    } catch (error) {
      console.error('Fehler beim Senden der Daten an den Server:', error.response ? error.response.data : error.message);
    }
  };
  

  return (
    <FormContainer onClick={onCancel}>
      <FormWrapper onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleSubmit}>
          <label>
            Mitarbeiter Name:
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </label>
          <label>
            Farbe:
            <Input type="color" name="farbe" value={formData.farbe} onChange={handleChange} style={{ backgroundColor: `${formData.farbe}` }} />
          </label>
          <label>
            Filiale:
            <Select
              isMulti
              name="filiale"
              options={filialeOptions}
              onChange={handleSelectChange}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.id}
              value={filialeOptions.filter(option => formData.filiale.includes(option.id))}
            />
          </label>
          <Button type="submit">Speichern</Button>
          <Button type="button" onClick={onCancel}>Abbrechen</Button>
        </Form>
      </FormWrapper>
    </FormContainer>
  );
}

export default AddNewMitarbeiter;
