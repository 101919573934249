
import React, { useState, useContext } from 'react';
import AuthContext from '../../context/authContext';
import {  Container,
  Background,
  ShapeFirst,
  ShapeLast,
  Form,
  FormHeader,
  Label,
  Input,
  Button,} from'./Login.element'

function Login() {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      setError('');  // Fehlerzustand leeren, falls das Login erfolgreich war
    } catch (err) {
      setError(err.message);  // Fehlerzustand setzen
    }
  };
  

  return (
    <Container>
      <Background>
        <ShapeFirst />
        <ShapeLast />
      </Background>
  
      <Form onSubmit={handleSubmit}>
        <FormHeader>Gronde Kalendar</FormHeader>
        {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Fehleranzeige */}
        <Label htmlFor="Username">Username</Label>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Label htmlFor="password">Passwort</Label>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit">Login</Button>
      </Form>
    </Container>
  );
  
};


export default Login;
