import React, { useState } from 'react';
import API from '../../context/axiosSetup';

function InputData() {
  const [jsonData, setJsonData] = useState('');


  const handleSendData = async () => {
    try {
      let parsedData = JSON.parse(jsonData); // Parse the JSON string to a JavaScript object
  
      while (parsedData.length > 0) {
        // Take the first 100 elements from the array
        const batch = parsedData.slice(0, 100);
  
        // Send the batch to the server
        const response = await API.post('/addJson', batch);
        console.log('Response:', response.data);
  
        // Remove the sent elements from the array
        parsedData = parsedData.slice(100);
  
        // Add a delay before sending the next batch
        await new Promise(resolve => setTimeout(resolve, 500)); // 500ms delay
      }
  
      console.log('All data has been sent successfully.');
    } catch (error) {
      console.error('Fehler beim Senden der Daten:', error);
    }
  };
  

  return (
    <div>
      <textarea
        value={jsonData}
        onChange={(e) => setJsonData(e.target.value)}
        placeholder="Geben Sie die JSON-Daten hier ein"
        rows="100"
        cols="5000"
        overflow='auto'
        style={{
          width: '100%',         // Full width of the container
          height: '300px',       // Fixed height to ensure scrolling
          overflowY: 'scroll',   // Always show vertical scrollbar when needed
          overflowX: 'auto',     // Horizontal scrollbar will show only if needed
          resize: 'none',        // Prevent manual resizing
        }}
      />
     <button 
  onClick={handleSendData} 
  style={{
    backgroundColor: '#4CAF50', 
    color: 'white', 
    padding: '10px 20px', 
    border: 'none', 
    borderRadius: '5px', 
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '10px'
    
  }}
>
  Daten senden
</button>
    </div>
  );
}

export default InputData;
