import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import API from '../../context/axiosSetup'
import {
  MitarbeiterContainer,
  AddNewButton,
  DetailsTable,
  TableCell,
  ActionButton,
  InputField
} from './MitarbeiterInfo.element';
import DeleteConformationComponent from '../DeletConformationForm/DeleteConformationComponent';

function MitarbeiterInfo({ mitarbeiter, removeMitarbeiter, updateMitarbeiter, filialeOptions, setSelectedMitarbeiter }) {

  const [editMode, setEditMode] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editedMitarbeiter, setEditedMitarbeiter] = useState(mitarbeiter);
  const [selectedFiliale, setSelectedFiliale] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    setEditedMitarbeiter(mitarbeiter);
    const initialFiliale = filialeOptions.filter(option =>
      mitarbeiter.filiale && mitarbeiter.filiale.includes(option.value)
    );
    setSelectedFiliale(initialFiliale);
  }, [mitarbeiter, filialeOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSelectedMitarbeiter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSelectedMitarbeiter]);

  const onSave = async () => {
    const filialeIds = selectedFiliale.map(option => option.id);
    const dataToSend = { ...editedMitarbeiter, filiale: filialeIds };
    try {
      const response = await API.post('/editMitarbeiter', dataToSend);
      console.log("Speichern erfolgreich", response.data);
      updateMitarbeiter({ ...editedMitarbeiter, filiale: selectedFiliale.map(option => option.value) });
    } catch (error) {
      console.error("Fehler beim Speichern der Mitarbeiterdaten", error);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      onSave();
    }
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedMitarbeiter(prevState => ({ ...prevState, [name]: value }));
  };

  const deleteItem = (id) => {
    setShowDeleteConfirmation(true);
    setDeleteItemId(id);
  };

  const handleConfirmDelete = async () => {
    if (!deleteItemId) return;
    await API.delete(`/deleteMitarbeiter/${deleteItemId}`);
    removeMitarbeiter(deleteItemId);
    setShowDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedFiliale(selectedOptions);
  };

  return (
    <div ref={containerRef}>
      <MitarbeiterContainer>
        <DetailsTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Farbe</th>
              <th>Filiale</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableCell>{mitarbeiter?.value}</TableCell>
              <TableCell>
                {editMode ? <InputField type="text" value={editedMitarbeiter?.label} onChange={handleChange} name="label" /> : mitarbeiter?.label}
              </TableCell>
              <TableCell style={{ background: editedMitarbeiter?.color }}>
                {editMode ? <InputField type="color" value={editedMitarbeiter?.color} onChange={handleChange} name="color" /> : <div style={{ background: editedMitarbeiter?.color, width: '100%', height: '100%' }}></div>}
              </TableCell>
              <TableCell>
                {editMode ? (
                  <Select
                    isMulti
                    name="filiale"
                    options={filialeOptions}
                    getOptionLabel={(option) => option.label} // Display name
                    getOptionValue={(option) => option.value} // Use value for values
                    onChange={handleSelectChange}
                    value={selectedFiliale}
                  />
                ) : (
                  Array.isArray(mitarbeiter.filiale) ? mitarbeiter.filiale.join(', ') : 'Keine Filiale'
                )}
              </TableCell>
            </tr>
            <tr>
              <TableCell>
                <ActionButton onClick={toggleEditMode}>{editMode ? 'Speichern' : 'Bearbeiten'}</ActionButton>
              </TableCell>
              <TableCell>
                <AddNewButton onClick={() => deleteItem(mitarbeiter?.value)}>×</AddNewButton>
              </TableCell>
            </tr>
          </tbody>
        </DetailsTable>

        {showDeleteConfirmation && (
          <DeleteConformationComponent
            showDeleteConfirmation={showDeleteConfirmation}
            onDeleteConfirmation={handleConfirmDelete}
            onCancelConfirmation={handleCancelDelete}
          />
        )}
      </MitarbeiterContainer>
    </div>
  );
}

export default MitarbeiterInfo;
