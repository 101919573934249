import React, { useState} from 'react';
import styled from 'styled-components';



const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FormWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.save {
    background-color: #28a745;
    color: white;

    &:hover {
      background-color: #218838;
    }
  }

  &.cancel {
    background-color: #dc3545;
    color: white;

    &:hover {
      background-color: #c82333;
    }
  }
`;

function AddnewFilialeForm({ onCancel,onSave,setFililaeInfo }) {

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    rollenum: '',
    passwort: '',
    passwortBestaetigen: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Überprüfung, ob die Passwörter übereinstimmen
    if (formData.passwort !== formData.passwortBestaetigen) {
      alert("Passwörter stimmen nicht überein.");
      return;
    }

    // Erstellung des Objekts für die neue Filiale
    const newFiliale = {
      name: formData.name,
      username: formData.username,
      rollenum: formData.rollenum,
      passwort: formData.passwort,
    };

    try {
      // Senden der POST-Anfrage mit axiosInstance
      onSave(newFiliale)
     // const response = await axiosInstance.post('/addFiliale', newFiliale);
  
      // Prüfung auf erfolgreiche Antwort
      
    } catch (error) {
      console.log(error);
      // Fehlerbehandlung, falls die Anfrage fehlschlägt
      console.error("Es gab einen Fehler beim Senden der Daten: ", error);
      alert("Fehler beim Hinzufügen der neuen Filiale. Bitte versuche es später erneut.");
    }
  };

  return (
    <FormContainer>
      <FormWrapper onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleSubmit}>
          <label>
            Name:
            <Input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Username:
            <Input type="text" name="username" value={formData.username} onChange={handleChange} required />
          </label>
          <label>
            Referenznummer:
            <Input type="text" name="rollenum" value={formData.rollenum} onChange={handleChange} required />
          </label>
          <label>
            Passwort:
            <Input type="password" name="passwort" value={formData.passwort} onChange={handleChange} required />
          </label>
          <label>
            Passwort bestätigen:
            <Input type="password" name="passwortBestaetigen" value={formData.passwortBestaetigen} onChange={handleChange} required />
          </label>
          <ButtonGroup>
            <Button className="save" type="submit">Speichern</Button>
            <Button className="cancel" type="button" onClick={onCancel}>Abbrechen</Button>
          </ButtonGroup>
        </Form>
      </FormWrapper>
    </FormContainer>
  );
}

export default AddnewFilialeForm;
