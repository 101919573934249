import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import API from '../../context/axiosSetup';
import MitarbeiterInfo from '../MitarbeiterInfo/MitarbeiterInfo';
import AddNewMitarbeiter from '../AddnewMitarbeiter/AddNewMitarbeiter';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
 
`;

const Button = styled.button`
  font-size: 16px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    margin: '0 auto',
    width: '50%'
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    border: '2px solid #007bff',
    borderRadius: '20px',
    padding: '4px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '20px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightgray' : 'white',
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  }),
};

function Dropdown() {
 
  const [mitarbeiter, setMitarbeiter] = useState([]);
  const [selectedMitarbeiter, setSelectedMitarbeiter] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const [filialeOptions, setFilialeOptions] = useState([]);

  useEffect(() => {
    const fetchFilialeInfo = async () => {
      try {
        const response = await API.get('/getFilialeInfos');
        console.log(response.data);
        const options = response.data.data.map(filiale => ({
          value: filiale.name,
          label: filiale.name,
          id: filiale.branches_id
        }));
        setFilialeOptions(options);
      } catch (error) {
        console.error('Fehler beim Laden der Filialen:', error);
      }
    };
  
    fetchFilialeInfo();
  }, []);  // Hinzugefügt axiosInstance als Abhängigkeit
  
  
  useEffect(() => {
    const fetchMitarbeiter = async () => {
      try {
        const response = await API.get('/getAllMitarbeiter');
       
        const mitarbeiterOptions = response.data.map(mitarbeiter => ({
          value: mitarbeiter.mitarbeiter_id,
          label: mitarbeiter.name,
          color: mitarbeiter.farbe,
          filiale: mitarbeiter.branches
        }));
        setMitarbeiter(mitarbeiterOptions);
      } catch (error) {
        console.error("Es gab einen Fehler beim Abrufen der Daten", error);
      }
    };
  
    fetchMitarbeiter();
  }, []);
  
  const handleChange = selectedOption => {
    setSelectedMitarbeiter(selectedOption);
  };

  const removeMitarbeiter = (id) => {
    setMitarbeiter(mitarbeiter.filter(mitarbeiter => mitarbeiter.value !== id));
  };

  const updateMitarbeiter = (updatedMitarbeiter) => {
    setSelectedMitarbeiter(updatedMitarbeiter);
  };

  return (
    <div>
      <Wrapper>
        <h1>Mitarbeiter Section</h1>
        <Select
          value={selectedMitarbeiter}
          onChange={handleChange}
          options={mitarbeiter}
          placeholder="Mitarbeiter auswählen..."
          isClearable
          isSearchable
          styles={customStyles}
        />
        <Button onClick={() => setShowAddNew(true)}>+</Button>
      </Wrapper>
      {selectedMitarbeiter && (
        <MitarbeiterInfo
          mitarbeiter={selectedMitarbeiter}
          removeMitarbeiter={removeMitarbeiter}
          updateMitarbeiter={updateMitarbeiter}
          filialeOptions={filialeOptions}
          setSelectedMitarbeiter={setSelectedMitarbeiter}
        />
      )}
      {showAddNew && <AddNewMitarbeiter onCancel={() => setShowAddNew(false)} filialeOptions={filialeOptions} />}
    </div>
  );
}

export default Dropdown;

