import React from 'react';

import styled  from 'styled-components';
import Filiale from '../../component/Filiale/Filiale';
import Dropdown from '../../component/MitarbeiterInput/Dropdown';
import InputData from '../../component/InputData/InputData';

const Container=styled.div`
height:1800px;
`;

function Home() {
  return (
    <Container>
      <Filiale />
      <Dropdown />
      <InputData />

    </Container>
  );
}


export default Home
