import React, { useState, useEffect,useContext} from 'react';
import API from '../../context/axiosSetup';
import EditFormComponent from '../EditFormKomponent/EditFormComponent';
import DeleteConformationComponent from '../DeletConformationForm/DeleteConformationComponent';
import AddnewFilialeForm from '../NewFilialeFormKomponent/AddnewFilialeForm';
import { 
  ListContainer, 
  ListItem, 
  StyledWrapper, 
  AddNewButton, 
  DropdownContainer, 
  DropdownButton, 
  DropdownContent, 
  DropdownItem 
} from './Filiale.element';
import AuthContext from '../../context/authContext';
function Filiale() {

  const [filialeInfo, setFililaeInfo] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const [editItemInfo, setEditItemInfo] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    rollenum: '',
    passwort: '',
    passwortBestaetigen: '',
    id: ''
  });
  const [changedFields, setChangedFields] = useState({});
  const { logout } = useContext(AuthContext);
  useEffect(() => {
    sendUserDataToServer();
  }, []);
  console.log(filialeInfo);
  
  const sendUserDataToServer = async () => {
    try {
      const response = await API.get('/getFilialeInfos');
      console.log('res', response.data.data);
      setFililaeInfo(response.data.data);
    } catch (error) {
      console.error('Fehler beim Laden der Filialen:', error);
    }
  };

  const editItem = (id) => {
    const filialeToEdit = filialeInfo.find(item => item.branches_id === id);
    setEditItemInfo(filialeToEdit);
    setFormData({
      name: filialeToEdit.name || '',
      username: '',
      rollenum: '',
      passwort: '',
      passwortBestaetigen: '',
      id: filialeToEdit.branches_id || ''
    });
    setShowEdit(true);
  };

  const deleteItem = (id) => {
    setDeleteItemId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/deleteFiliale/${deleteItemId}`);
      setFililaeInfo(filialeInfo.filter(item => item.branches_id !== deleteItemId));
      setShowDeleteConfirmation(false);
      setDeleteItemId(null);
    } catch (error) {
      console.error('Fehler beim Löschen der Filiale:', error);
    }
  };

  const handleAddNew = async (newFiliale) => {
    try {
      const response = await API.post('/addFiliale', newFiliale);
      setFililaeInfo([...filialeInfo, newFiliale]);
      setShowAddNew(false);
      
    } catch (error) {
      console.error('Fehler beim Hinzufügen der neuen Filiale:', error);
    }
  };

  const handleEditSave = async () => {
    const updatedFiliale = {};
    for (const key in changedFields) {
      if (changedFields[key]) {
        updatedFiliale[key] = formData[key];
      }
    }
    updatedFiliale.id = formData.id;

    try {
      const response = await API.post('/editFiliale', { 
        method: 'POST', 
        data: updatedFiliale 
      });
      if (response.status === 200 || response.status === 201) {
        setFililaeInfo(filialeInfo.map(item => (item.branches_id === updatedFiliale.id ? response.data : item)));
        setShowEdit(false);
        setChangedFields({});
      }
    } catch (error) {
      console.error("Es gab einen Fehler beim Senden der Daten: ", error);
      alert("Fehler beim Bearbeiten der Filiale. Bitte versuche es später erneut.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setChangedFields(prevState => ({
      ...prevState,
      [name]: true
    }));
  };

  return (
    <StyledWrapper>
      <div style={{ maxWidth: '80%', width: '100%' }}>
        <button onClick={logout}>Logout</button>
        <h1 style={{ textAlign: 'center' }}>Filialen</h1>
        <ListContainer>
          {filialeInfo && filialeInfo.length > 0 && filialeInfo.map(item => (
            <ListItem key={item.branches_id}>
              <span>ID: {item.branches_id}, Name: {item.name}, Role: {item.was}</span>
              <DropdownContainer>
                <DropdownButton>Actions</DropdownButton>
                <DropdownContent>
                  <DropdownItem onClick={() => editItem(item.branches_id)}>Edit</DropdownItem>
                  <DropdownItem onClick={() => deleteItem(item.branches_id)}>Delete</DropdownItem>
                </DropdownContent>
              </DropdownContainer>
            </ListItem>
          ))}
          <ListItem>
            <AddNewButton onClick={() => setShowAddNew(true)}>+</AddNewButton>
          </ListItem>
        </ListContainer>
        {showEdit && editItemInfo && (
          <EditFormComponent
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleEditSave}
            setShowEdit={setShowEdit}
          />
        )}
        {showDeleteConfirmation && 
          <DeleteConformationComponent
            showDeleteConfirmation={showDeleteConfirmation}
            onDeleteConfirmation={handleConfirmDelete}
            onCancelConfirmation={() => setShowDeleteConfirmation(false)}
          />
        }
        {showAddNew && (
          <AddnewFilialeForm
            filialeInfo={filialeInfo}
            setFililaeInfo={setFililaeInfo}
            onCancel={() => setShowAddNew(false)}
            onSave={handleAddNew}
          />
        )}
      </div>
    </StyledWrapper>
  );
}

export default Filiale;
